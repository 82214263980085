import React, { useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { Button, IconEnum, Heading, Link, Icon, IconSizeEnum, StaticImage, Spacer } from '@atoms/index'
import { Accordion, Form, Tab, TextInput, useForm } from '@client/components/molecules'
import { SiteHelper } from '@client/lib/SiteHelper'
import { ResponsiveProperty, ResponsivePXValue, theme } from '@components/Theme'
import { useSubsctibeToAcListMutation } from '@hooks/api'

const Container = styled.div`
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  width: 100%;

  .social-link-container {
    ${ResponsiveProperty('justify-content', { mobile: 'center', tablet: 'center', desktop: 'flex-end' })}
    ${ResponsiveProperty('align-items', { mobile: 'center', tablet: 'center', desktop: 'flex-start' })}
  }

  .heading {
    font-weight: 500;
    color: ${(props): string => props.theme.colors.greys.darkCodGrey};
    ${ResponsivePXValue('margin-bottom', '16px')}
  }
  
  .link {
    font-size: 10px;
    font-weight: 500;
    color: ${(props): string => props.theme.colors.greys.boulder};
    ${ResponsivePXValue('margin-bottom', '4px')}
  }

  .accordion-tab {
     background-color: ${(props): string => props.theme.colors.whites.pureWhite};

     .titleText {
      font-weight: 500;
      color: ${(props): string => props.theme.colors.greys.darkCodGrey};
     }

     svg {
      color: ${(props): string => props.theme.colors.greys.darkCodGrey} !important;
     }
  }

  .flex {
    display: flex;
  }
`

const KitchenContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsiveProperty('align-items', { mobile: 'center', tablet: 'center', desktop: 'baseline' })}
  ${ResponsiveProperty('justify-content', { mobile: 'flex-start', tablet: 'flex-start', desktop: 'center' })}
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '16px 0', desktop: '16px 0' })}
   ${ResponsivePXValue('margin-bottom', '16px')}
`
const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('margin-right', { mobile: '0', tablet: '0', desktop: '16px' })}

`

const TextInputContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '378px', desktop: '378px' })}
  ${ResponsivePXValue('margin-right', { mobile: '0', tablet: '0', desktop: '16px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '16px', desktop: '0' })}
`

const FooterContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsiveProperty('align-items', { mobile: 'center', tablet: 'center', desktop: 'flex-start' })}
  ${ResponsiveProperty('justify-content', { desktop: 'space-evenly' })}
  ${ResponsivePXValue('padding', { mobile: '24px 12px', tablet: '24px 16px', desktop: '24px 16px' })}
  border-top: 1px solid ${(props): string => props.theme.colors.whites.desertStorm};
  border-bottom: 1px solid ${(props): string => props.theme.colors.whites.desertStorm};
`

const LinkContainer = styled.div`

  display: flex;
  flex-direction: column;
  
  ${(props): string => props.theme.desktop} {
    padding: 0.75vw;
    ${ResponsivePXValue('padding', '12px')}
  }

  ${(props): string => props.theme.ultra} {
    ${ResponsivePXValue('padding', '12px')}
  }

`

const IconContainer = styled.div`
  display: flex;

  .social-icon {
    ${ResponsivePXValue('margin-right', '16px')}
  }
`
const AppDownloadContainer = styled.div`
  display: flex;
`
const StoreDownloadContainer = styled.a`
  display: flex;
  text-decoration: none;

  ${ResponsivePXValue('height', '36px')}
`
const SupportLinkContainer = styled.div`
  display: flex;
`
const IconLinkContainer = styled.a`
  display: flex;
  text-decoration: none;
`

const ucookLinks = [{
  title: 'MEAL KITS',
  href: '/meal-kit/',
}, {
  title: 'FROZEN',
  href: '/frozen/',
}, {
  title: 'WINE',
  href: '/wine/',
}, {
  title: 'MARKET',
  href: '/market/',
}]
const mealKitsAndWineLinks = [{
  title: 'MEAL KITS',
  href: '/meal-kit/',
}, {
  title: 'WINE',
  href: '/wine/',
}, {
  title: 'FEATURED FARMS',
  href: '/wine/featured-farms',
}]
const frozenLinks = [{
  title: 'CRAFT MEAL',
  href: '/frozen/craft-meal',
}, {
  title: 'CRAFT PIZZA',
  href: '/frozen/craft-pizza',
}, {
  title: 'CRAFT DESSERT',
  href: '/frozen/craft-dessert',
}, {
  title: 'CRAFT KIDS',
  href: '/frozen/craft-kids',
},
]

const companyLinks = [{
  title: 'ABOUT',
  href: '/cms/about-ucook',
}, {
  title: 'CAREERS',
  href: 'https://www.silvertreebrands.com/careers-silvertreebrands',
  target: '_blank',
  blank: true,
}, {
  title: 'RECYCLE',
  href: '/cms/recycle',
}]

const callLinks = [{
  icon: 'call',
  title: '021 447 4424',
  href: 'tel:0214474424',
  isCallNumber: true,
}, {
  icon: 'whatsapp',
  title: '072 020 1555',
  href: 'https://wa.me/27720201555?text=GoodDay%20UCOOK%20,%20', // experimental but coooool
  isCallNumber: true,
},
]

const supportLinks = [{
  title: 'FAQ’S',
  href: 'https://support.ucook.co.za/hc/en-us',
}, {
  title: 'CONTACT',
  href: 'https://support.ucook.co.za/hc/en-us/requests/new',
},
...callLinks,
]

enum TabEnum {
  MEAL_KITS_WINE = 'MEAL KITS & WINE',
  FROZEN = 'FROZEN',
  COMPANY = 'COMPANY',
  SUPPORT = 'SUPPORT',
}
interface FooterState {
  validEmail: boolean
  activeTab: string
}

const DEFAULT_STATE: FooterState = {
  validEmail: false,
  activeTab: 'ucook',
}

export function Footer(): JSX.Element {

  const [form] = useForm()
  const [addLead, { loading }] = useSubsctibeToAcListMutation()
  const { addToast } = useToasts()

  const [state, setState] = useState<FooterState>({ ...DEFAULT_STATE })

  const isDesktop = theme.isDesktop() || theme.isUltra()

  const socialIcons = [{
    icon: IconEnum.LOGO_FACEBOOK,
    color: theme.colors.misc.facebookBlue,
    hoverColor: theme.colors.misc.facebookBlue,
    title: 'FACEBOOK',
    href: 'https://www.facebook.com/ucooksa/',
    target: '_blank',
    blank: true,
  }, {
    icon: IconEnum.LOGO_INSTAGRAM,
    color: theme.colors.misc.instagramPink,
    hoverColor: theme.colors.misc.instagramPink,
    title: 'INSTAGRAM',
    href: 'https://www.instagram.com/ucooksa/',
    target: '_blank',
    blank: true,
  }, {
    icon: IconEnum.LOGO_TWITTER,
    color: theme.colors.misc.twitterBlue,
    hoverColor: theme.colors.misc.twitterBlue,
    title: 'TWITTER',
    href: 'https://twitter.com/UCOOKSA',
    target: '_blank',
    blank: true,
  }, {
    icon: IconEnum.LOGO_YOUTUBE,
    color: theme.colors.misc.youTubeRed,
    hoverColor: theme.colors.misc.youTubeRed,
    title: 'YOUTUBE',
    href: 'https://www.youtube.com/channel/UCi02eriePmaXaj-wyoeGGmg',
    target: '_blank',
    blank: true,
  }, {
    icon: IconEnum.LOGO_TIKTOK,
    color: theme.colors.greys.darkCodGrey,
    hoverColor: theme.colors.greys.darkCodGrey,
    title: 'TIKTOK',
    href: 'https://www.tiktok.com/@ucooksouthafrica',
    target: '_blank',
    blank: true,
  }]

  const _handleSubmit = async (data: { email: string }): Promise<void> => {
    try {
      await addLead({
        variables: {
          input: {
            emailAddress: data.email as string,
            listId: '152',
          },
        },
      })
      addToast('You have successfully subscribed!', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      form.setFieldsValue({ email: '' }) //
    }
  }

  const _handleFieldChange = ({ email }: { email: string }): void => {
    const isValid = SiteHelper.validateEmail(email)
    setState((prevState) => update(prevState, {
      validEmail: {
        $set: isValid,
      },
    }))
  }

  const getSupportIcon = (icon: string): IconEnum => {
    switch (icon) {
      case 'call':
        return IconEnum.CALL_OUTLINE
      case 'whatsapp':
        return IconEnum.LOGO_WHATSAPP
    }
  }

  let linkItem: { title: string, href: string, isCallNumber?: boolean, icon?: string}
  let iconItem: { icon: IconEnum, color: string, href?: string, target?: string }
  let index: number

  return (
    <Container>
      <Form form={form} onFinish={_handleSubmit} onValuesChange={_handleFieldChange} loading={loading}>
        <KitchenContainer>
          <TextContainer>
            <Heading className='heading' variant='h6'>KEEP IN TOUCH WITH OUR KITCHEN</Heading>
          </TextContainer>
          <TextInputContainer>
            <TextInput
              variant='email'
              name='email'
              label='Email address'
              showLabel={false}
              placeholder='Enter your email'
              rules={[{ required: true, message: 'Please input your email address' }]}
              className='email-input' />
          </TextInputContainer>
          <Button
            title='SIGN ME UP'
            color='green'
            fullWidth={theme.isMobile()}
            disabled={!state.validEmail}
            onClick={() => form.submit()} />
        </KitchenContainer>
      </Form>
      <FooterContainer>
        <If condition={isDesktop}>
          <LinkContainer>
            <Heading className='heading' variant='h6'>
            PRODUCT
            </Heading>
            <For each='linkItem' index='index' of={ucookLinks}>
              <Link key={index} className='link' variant='l2' href={linkItem.href}>{linkItem.title}</Link>
            </For>
          </LinkContainer>
          <LinkContainer>
            <Heading className='heading' variant='h6'>
            COMPANY
            </Heading>
            <For each='linkItem' index='index' of={companyLinks}>
              <Link key={index} className='link' variant='l2' href={linkItem.href}>{linkItem.title}</Link>
            </For>
          </LinkContainer>
          <LinkContainer>
            <Heading className='heading' variant='h6'>
            SUPPORT
            </Heading>
            <For each='linkItem' index='index' of={supportLinks}>
              <SupportLinkContainer key={index}>
                <If condition={!!linkItem?.icon}>
                  <Icon
                    className='social-icon'
                    color={theme.colors.greys.boulder}
                    icon={getSupportIcon(linkItem.icon)}
                    size={IconSizeEnum.EXTRA_SMALL} />
                  <Spacer universal='8px' variant='horizontal' />
                </If>
                <Link className='link' variant='l2' href={linkItem.href}>{linkItem.title}</Link>
              </SupportLinkContainer>
            </For>
          </LinkContainer>
        </If>
        <LinkContainer className='social-link-container'>
          <AppDownloadContainer>
            <StoreDownloadContainer href='https://apps.apple.com/us/app/ucook/id1669025266' target='_blank'>
              <StaticImage
                staticId='app-store-download'
                lazy={false}
                objectFit='contain'
                mobileWidth={768}
                tabletWidth={1024}
                desktopWidth={1600} />
            </StoreDownloadContainer>
            <Spacer universal='16px' variant='horizontal' />
            <StoreDownloadContainer href='https://play.google.com/store/apps/details?id=io.gonative.android.jaboxo&hl=en_ZA&gl=US' target='_blank'>
              <StaticImage
                staticId='google-play-download'
                lazy={false}
                objectFit='contain'
                mobileWidth={768}
                tabletWidth={1024}
                desktopWidth={1600} />
            </StoreDownloadContainer>
          </AppDownloadContainer>
          <Spacer universal='24px' />
          <IconContainer>
            <For each='iconItem' index='index' of={socialIcons}>
              <IconLinkContainer key={index} href={iconItem.href} target={iconItem.target}>
                <Icon
                  className='social-icon'
                  color={iconItem.color}
                  icon={iconItem.icon}
                  size={IconSizeEnum.SMALL} />
              </IconLinkContainer>
            </For>
          </IconContainer>
        </LinkContainer>
        <If condition={!isDesktop}>
          <Spacer universal='24px' />
          <Accordion
            activeTab={state.activeTab}
            onTabChange={(activeTab: TabEnum): void => setState(prevState => ({ ...prevState, activeTab }))}>
            <Tab borderColor={theme.colors.greys.platinum} className='accordion-tab' tab={TabEnum.MEAL_KITS_WINE} title={TabEnum.MEAL_KITS_WINE}>
              <For each='linkItem' index='index' of={mealKitsAndWineLinks}>
                <Link key={index} className='link flex' variant='l2' href={linkItem.href}>{linkItem.title}</Link>
              </For>
            </Tab>
            <Tab borderColor={theme.colors.greys.platinum} className='accordion-tab' tab={TabEnum.FROZEN} title={TabEnum.FROZEN}>
              <For each='linkItem' index='index' of={frozenLinks}>
                <Link key={index} className='link flex' variant='l2' href={linkItem.href}>{linkItem.title}</Link>
              </For>
            </Tab>
            <Tab borderColor={theme.colors.greys.platinum} className='accordion-tab' tab={TabEnum.COMPANY} title={TabEnum.COMPANY}>
              <For each='linkItem' index='index' of={companyLinks}>
                <Link key={index} className='link flex' variant='l2' href={linkItem.href}>{linkItem.title}</Link>
              </For>
            </Tab>
            <Tab borderColor={theme.colors.greys.platinum} className='accordion-tab' tab={TabEnum.SUPPORT} title={TabEnum.SUPPORT}>
              <For each='linkItem' index='index' of={supportLinks}>
                <Link key={index} className='link flex' variant='l2' href={linkItem.href}>{linkItem.title}</Link>
              </For>
            </Tab>
          </Accordion>
        </If>
      </FooterContainer>
    </Container>
  )
}
