import React, { useEffect, useRef, useState } from 'react'

import { format } from 'date-fns'
import Cookies from 'js-cookie'
import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled, { CSS } from 'styled-components'

import { AppPlugin, APP_DEFAULT_STATE } from '@api/local'
import { Button, Heading, Paragraph, Pill, Spacer } from '@atoms/index'
import { scrollToSelector } from '@client/components/utility'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { LoadEffect, PageSectionPadding, ResponsiveProperty, ResponsivePXValue, SectionContainer, SectionHorizontalNegativeMargin, SectionHorizontalPadding, theme } from '@components/Theme'
import { useUserCartQuery, useUserDetailsQuery, useGetAppQuery, UserSubscriptionFragment, useActivateSubscription, RegisteredUserDetailsFragment, UserDetailsFragment } from '@hooks/api'
import { Notification } from '@molecules/index'
import { DeviceTypeEnum, NumberOfPortionsEnum, UserSubscriptionStatusEnum, WeekDayEnum } from '@uctypes/api/globalTypes'

import { FrozenAislesCarousel, MarketAislesCarousel, MealKitAislesCarousel, WineAislesCarousel } from '../aisles'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const ContentContainer = styled.div<{ $isMobile?: boolean, $isNativeApplication?: boolean}>`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${(props): CSS => props?.$isMobile ? SectionHorizontalPadding : PageSectionPadding};

  .link {
    display: flex;
    align-self: center;
  }

  .notification-message {
    align-self: stretch;
  }
    ${(props): CSS => props?.$isMobile ? SectionHorizontalPadding : PageSectionPadding};

    .link {
      display: flex;
      align-self: center;
    }

    .sticky-top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: sticky;
      z-index: 12;
      ${ResponsivePXValue('top', { mobile: '48px', tablet: '60px', desktop: '60px' })}
      ${(props): CSS => props?.$isNativeApplication ? 'top: 0' : ResponsivePXValue('top', { mobile: '48px', tablet: '60px', desktop: '60px' })};
      ${(props): CSS => props?.$isMobile ? SectionHorizontalPadding : undefined};
      ${(props): CSS => props?.$isMobile ? SectionHorizontalNegativeMargin : undefined};
    }

   .notification-message {
      align-self: stretch;
    }
`
const PaddedSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
const WelcomeContentContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;

  ${ResponsivePXValue('width', { mobile: '100%' })}
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsiveProperty('align-items', { mobile: 'center', tablet: 'flex-start', desktop: 'flex-start' })}
`
const WelcomeActionContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('width', { mobile: '100%' })}
`
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
`
const OpaqueContainer = styled.div`
  display: flex;
  align-items: center;
  ${ResponsivePXValue('padding', { mobile: '10px 12px', tablet: '12px 16px', desktop: '12px 16px' })}
`
const PillContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('padding', { mobile: '8px 0', tablet: '16px', desktop: '16px' })}

  .pill {
    &:hover {
      cursor: pointer;
    }
  }
 `

const Section = styled.div<{ $backgroundColor?: string, $disabled?: boolean, $rounded?: boolean, $extraPadded?: boolean, $isMobile?: boolean, $isLoading?: boolean }>`
  ${(props): CSS => SectionContainer(props.$backgroundColor)};
  ${(props): CSS => props?.$disabled || props?.$isLoading ? LoadEffect : undefined};
  ${(props): CSS => props?.$rounded ? ResponsivePXValue('border-radius', { desktop: '10px' }) : undefined};
  ${(props): CSS => props?.$extraPadded ? ResponsivePXValue('padding', { desktop: '0 32px', mobile: '0 16px' }) : undefined};
  ${(props): CSS => props?.$extraPadded && props?.$isMobile ? SectionHorizontalNegativeMargin : undefined};

  .section {
    ${ResponsivePXValue('width', { mobile: '100%' })}
  }

`
const RefContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('scroll-margin-top', { mobile: '145px', tablet: '165px', desktop: '165px' })}
`
interface PersonalizedHomeState {
  activePill: string
  loading: boolean
}

const DEFAULT_STATE: PersonalizedHomeState = {
  activePill: 'Frozen',
  loading: false,
}

export function PersonalizedHome(): JSX.Element {

  const [state, setState] = useState<PersonalizedHomeState>({ ...DEFAULT_STATE })

  const config = useConfig()
  const { addToast } = useToasts()
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [activateSubscription] = useActivateSubscription()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isScrollingProgrammatically = useRef(false)
  const urlSuffix = '?source=personalizedHome'
  const aislesCarouselMaxWidth = config.isBrowser() && window.innerWidth < 1440 ? 'calc(100dvw - 180px)' : '1248px'
  const dataLimit = 6
  const fadeThresholdLimit = 3

  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const subscription: UserSubscriptionFragment = registeredUser?.subscriptions?.find((subscription) => subscription.id === userCartData?.currentUser?.activeMenu?.subscription?.id)
  const hasActiveSubscription = subscription?.status === UserSubscriptionStatusEnum.ACTIVE

  const welcomeSectionBackgroundColor = hasActiveSubscription ? theme.colors.greens.fruitSalad : theme.colors.reds.cautionRed
  const stickyPills = ['Frozen', 'Market', 'Wine', 'Mealkit']
  const onDemandMessages = {
    Frozen: 'Long week ahead? Get dinner ready in only a few minutes with delicious and easy Frozen Craft Meals!',
    Market: 'From pantry staples and tasty treats to fresh faves and everyday essentials, shop all you need now!',
    Wine: 'Find the perfect match for your meal! Choose from over 60 wines and find your new favourites.',
  } as { [key: string]: string }

  const resumeDate = userCartData?.currentUser?.activeMenu?.subscription?.resumeDate || null
  const displayBillingDates = hasActiveSubscription || resumeDate !== null

  const _getNextWednesday = (date: Date): Date => {
    const result = new Date(date)
    result.setDate(result.getDate() + ((3 - result.getDay() + 7) % 7))
    return result
  }

  const _getNextSundayOrMonday = (date: Date, day: WeekDayEnum): Date => {
    const result = new Date(date)
    const targetDay = day === WeekDayEnum.SUNDAY ? 0 : 1 // 0 for Sunday, 1 for Monday
    result.setDate(result.getDate() + ((targetDay - result.getDay() + 7) % 7))
    return result
  }

  const _calculateDates = (subscription: UserSubscriptionFragment, currentDate: Date): { billingDate: Date, deliveryDate: Date } => {
    let billingDate: Date

    if (subscription.status === UserSubscriptionStatusEnum.ACTIVE) {
      billingDate = _getNextWednesday(currentDate)
    } else if (subscription.status === UserSubscriptionStatusEnum.PAUSED && resumeDate) {
      const parsedResumeDate = new Date(resumeDate)

      if (isNaN(parsedResumeDate.getTime())) {
        throw new Error('Invalid resume date format.')
      }

      billingDate = parsedResumeDate

      // Check if it's Wednesday and before 9 AM
      if (currentDate.getDay() === 3 && currentDate.getHours() < 9) {
        billingDate.setDate(parsedResumeDate.getDate())
      } else {
        billingDate.setDate(parsedResumeDate.getDate() + 7)
      }

      billingDate = _getNextWednesday(billingDate)
    } else {
      throw new Error('Invalid subscription status or missing resume date for paused subscription.')
    }

    const deliveryDate = _getNextSundayOrMonday(billingDate, subscription?.deliveryOption)

    return { billingDate, deliveryDate }
  }

  const _getFormattedDates = (): { formattedBillingDate: string | null, formattedDeliveryDate: string | null } => {

    if (!subscription || !displayBillingDates) return { formattedBillingDate: '', formattedDeliveryDate: '' }

    try {
      const currentDate = new Date()
      const { billingDate, deliveryDate } = _calculateDates(subscription, currentDate)
      const formattedBillingDate = billingDate ? format(billingDate, 'dd MMM') : null
      const formattedDeliveryDate = deliveryDate ? format(deliveryDate, 'dd MMM') : null

      // console.log(`Billing Date: ${formattedBillingDate}, Delivery Date: ${formattedDeliveryDate}`)

      return { formattedBillingDate, formattedDeliveryDate }

    } catch (error) {
      console.error(error.message)
    }
  }

  const { formattedBillingDate, formattedDeliveryDate } = _getFormattedDates()

  const isNativeApplication = appData.app.isNativeApplication

  const _getPillColor = (pill: string): string => state.activePill === pill ? theme.colors.whites.pureWhite : theme.colors.greys.liteCodGrey
  const _getPillBackgroundColor = (pill: string): string => state.activePill === pill ? theme.colors.greens.fruitSalad : theme.colors.whites.alabaster

  const _handlePillClick = (pill: string): void => {

    isScrollingProgrammatically.current = true

    setState((prevState) => update(prevState, {
      activePill: { $set: pill },
    }))

    scrollToSelector(`#${pill}AislesContainer`, 'start', 'start')

    if (config.isBrowser()) {
      window.setTimeout(() => {
        isScrollingProgrammatically.current = false
      }, 1000)
    }
  }

  const _handleActivateSubscription = async () => {

    try {
      setState((prevState) => update(prevState, {
        loading: { $set: true },
      }))
      await activateSubscription({
        variables: {
          id: userCartData?.currentUser?.activeMenu?.subscription?.id,
          reset: !userCartData?.currentUser?.checkoutStatus?.hasConfiguredBox,
        },
        refetchQueries: SiteHelper.getUserRefetchQueries(),
        awaitRefetchQueries: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setState((prevState) => update(prevState, {
        loading: { $set: false },
      }))
    }
  }

  const handleScroll = () => {

    const activePill = stickyPills.findIndex(pill => {
      const aislesContainer = document.getElementById(`${pill}AislesContainer`)
      if (aislesContainer) {
        const { top } = aislesContainer.getBoundingClientRect()
        const offset = isMobile ? 145 : 165 // Offset from the top
        return top - offset <= 0 && top + aislesContainer.offsetHeight > offset
      }
      return false
    })

    if (activePill !== -1 && !isScrollingProgrammatically.current) {
      setState((prevState) => update(prevState, {
        activePill: { $set: stickyPills[activePill] },
      }))
    }
  }

  useEffect(() => {
    if (config.isBrowser()) {
      window.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (config.isBrowser()) {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    const defaultPortions = subscription?.numberOfPortions
    const cookiedPortion = Cookies.get('defaultPortion') as unknown as NumberOfPortionsEnum

    if (defaultPortions) {
      AppPlugin.shared().setDefaultPortions(defaultPortions)
      Cookies.set('defaultPortion', String(defaultPortions), { expires: 365 })
    } else if (cookiedPortion) {
      AppPlugin.shared().setDefaultPortions(cookiedPortion)
    }
  }, [userDetailsData])
  // useAnalytics need to add every item view on GA here. WOW this is an expensive page place within every Aisle individually and wherever the products are pulled
  let pill: string
  const impersonationText = (userDetailsData?.currentUser as RegisteredUserDetailsFragment)?.impersonatedBy
    ? ' (IMPERSONATING)'
    : ''

  return (
    <Container>
      <ContentContainer $isMobile={isMobile} $isNativeApplication={isNativeApplication}>
        <Section $backgroundColor={welcomeSectionBackgroundColor} $rounded $extraPadded $isMobile={isMobile} $isLoading={userDetailsLoading || state.loading}>
          <PaddedSection className='section'>
            <Spacer mobile='16px' desktop='24px' />
            <WelcomeContentContainer>
              <Heading variant={isMobile ? 'h5' : 'h2'} color={theme.colors.whites.pureWhite} fontWeight={600}>Welcome back, {userDetailsData?.currentUser?.firstName+impersonationText}!</Heading>
              <WelcomeActionContainer>
                <FlexContainer>
                  <If condition={displayBillingDates}>
                    <OpaqueContainer>
                      <Paragraph variant='p2' color={theme.colors.whites.pureWhite} bold>Billing: {formattedBillingDate}</Paragraph>
                      <Spacer universal='16px' variant='horizontal' />
                      <Paragraph variant='p2' color={theme.colors.whites.pureWhite} bold>|</Paragraph>
                      <Spacer universal='16px' variant='horizontal' />
                      <Paragraph variant='p2' color={theme.colors.whites.pureWhite} bold>Delivery: {formattedDeliveryDate}</Paragraph>
                    </OpaqueContainer>
                    <Spacer desktop='24px' variant='horizontal' />
                  </If>
                  <Choose>
                    <When condition={hasActiveSubscription}>
                      <Button color='white' fullWidth={isMobile} variant='secondary' href='/me/subscriptions' title='MANAGE SUBSCRIPTION' />
                    </When>
                    <Otherwise>
                      <Paragraph variant='p2' color={theme.colors.whites.pureWhite}>Reactivate your account to add meal kits</Paragraph>
                      <Spacer desktop='24px' variant='horizontal' />
                      <Spacer mobile='8px' />
                      <Button color='white' fullWidth={isMobile} variant='secondary' onClick={_handleActivateSubscription} title='ACTIVATE NOW' />
                    </Otherwise>
                  </Choose>
                </FlexContainer>
                <Spacer desktop='16px' variant='horizontal' />
                <Spacer mobile='8px' />
              </WelcomeActionContainer>
            </WelcomeContentContainer>
            <Spacer mobile='16px' desktop='24px' />
            {/* <Spacer desktop='40px' />
            <Spacer mobile='16px' /> */}
          </PaddedSection>
        </Section>
        <Spacer mobile='16px' desktop='24px' />
        <Section className='sticky-top' $backgroundColor={theme.colors.whites.pureWhite} $rounded>
          <Spacer mobile='16px' desktop='12px' />
          <PaddedSection className='section'>
            <PillContainer>
              <For each='pill' of={stickyPills}>
                <Pill
                  className='pill'
                  title={pill}
                  textColor={_getPillColor(pill)}
                  backgroundColor={_getPillBackgroundColor(pill)}
                  onClick={() => _handlePillClick(pill)} />
                <Spacer mobile='16px' desktop='16px' variant='horizontal' />
              </For>
            </PillContainer>
          </PaddedSection>
          <Spacer mobile='8px' desktop='12px' />
        </Section>
        <Section>
          <PaddedSection className='section'>
            <RefContainer id='FrozenAislesContainer'>
              <Spacer desktop='24px' />
              <Notification
                className='notification-message'
                text={onDemandMessages.Frozen}
                backgroundColor={theme.colors.misc.lightGreen}
                textColor={theme.colors.greys.liteCodGrey} />
              <Spacer mobile='12px' desktop='24px' />
              <FrozenAislesCarousel isMiniCard={isMobile} urlSuffix={urlSuffix} aislesMaxWidth={aislesCarouselMaxWidth} dataLimit={dataLimit} fadeThresholdLimit={fadeThresholdLimit}/>
            </RefContainer>
            <RefContainer id='MarketAislesContainer'>
              <Spacer desktop='24px' />
              <Notification
                className='notification-message'
                text={onDemandMessages.Market}
                backgroundColor={theme.colors.misc.lightGreen}
                textColor={theme.colors.greys.liteCodGrey} />
              <Spacer mobile='12px' desktop='24px' />
              <MarketAislesCarousel isMiniCard={isMobile} aislesMaxWidth={aislesCarouselMaxWidth} urlSuffix={urlSuffix} dataLimit={dataLimit} fadeThresholdLimit={fadeThresholdLimit}/>
            </RefContainer>
            <RefContainer id='WineAislesContainer'>
              <Spacer desktop='24px' />
              <Notification
                className='notification-message'
                text={onDemandMessages.Wine}
                backgroundColor={theme.colors.misc.lightGreen}
                textColor={theme.colors.greys.liteCodGrey} />
              <Spacer mobile='12px' desktop='24px' />
              <WineAislesCarousel isMiniCard={isMobile} aislesMaxWidth={aislesCarouselMaxWidth} urlSuffix={urlSuffix} dataLimit={dataLimit} fadeThresholdLimit={fadeThresholdLimit}/>
            </RefContainer>
          </PaddedSection>
        </Section>
        <Section $isLoading={userDetailsLoading}>
          <PaddedSection className='section'>
            <RefContainer id='MealkitAislesContainer'>
              <Spacer mobile='12px' desktop='24px' />
              <Heading variant={isMobile ? 'h5' : 'h3'} fontWeight={600}>Pick your favourite dinners:</Heading>
              <Spacer mobile='12px' desktop='24px' />
              <MealKitAislesCarousel urlSuffix={urlSuffix} aislesMaxWidth={aislesCarouselMaxWidth} dataLimit={dataLimit} fadeThresholdLimit={fadeThresholdLimit}/>
            </RefContainer>
          </PaddedSection>
        </Section>
      </ContentContainer>
    </Container>
  )
}
