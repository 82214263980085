import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled, { CSS } from 'styled-components'
import { Stage, Transition } from 'transition-hook'

import { APP_DEFAULT_STATE } from '@api/local'
import { CartPlugin } from '@api/local/CartPlugin'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { Heading, IconEnum, Paragraph, Pill, ResponsiveImage, Spacer, Button, Icon } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useAnalytics } from '@client/hooks/UseAnalytics'
import { Ellipsis, ResponsivePXValue, theme, ZeroSpace } from '@components/Theme'
import { MealKitDishListFragment, RegisteredUserDetailsFragment, UpSellFrozenMealDishFragment, UpSellMarketProductFragment, UpSellWineFragment, UserCartDocument, UserDetailsFragment, UserMenuFragment, UserSubscriptionFragment, useAddDishToUserMenuMutation, useGetAppQuery, useRemoveDishFromUserMenuMutation, useUserCartQuery, useUserDetailsQuery } from '@hooks/api'
import { DiscoveryVitality, UpSellProductCard, SelectInput, SelectOption, Form, useForm, FieldData, FloatingUpSell } from '@molecules/index'
import { LunchModal, MealkitModal, MealkitModalEnum, ReactivateSubscriptionModal } from '@organisms/index'
import { CategoryBackgroundEnum, DeviceTypeEnum, NumberOfPortionsEnum, PortionSizeEnum, ProductAvailabilityEnum, ProductStockStatusEnum } from '@uctypes/api/globalTypes'

const TRANSITION_DURATION = 350

const Container = styled.div<{$displayUpSellProducts: boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: ${(props): string => props.$displayUpSellProducts ? 'initial' : 'hidden'};
  z-index: ${(props): string => props.$displayUpSellProducts ? 'initial' : '7'};;

  ${ResponsivePXValue('width', { mobile: 'calc(100vw - 32px)', tablet: '288px', desktop: '408px' })}

  .flex-1{
    flex: 1;
  }
  
`

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .no-margin {    
    align-items: center;
    height: fit-content;
    ${ResponsivePXValue('padding-bottom', '17px')}
  }

  .flex-pill {
    ${ResponsivePXValue('height', '24px')}
  }

`
const ImageContainer = styled.div<{ $displayUpSellProducts: boolean }>`
   display: flex;
   flex: 1;
   cursor: pointer;
   
  ${ResponsivePXValue('height', { mobile: '162px', tablet: '162px', desktop: '272px' })}
  ${ResponsivePXValue('max-height', { mobile: '162px', tablet: '162px', desktop: '272px' })}

  img {
    aspect-ratio: 3 / 2;

     ${(props): CSS => {
    if (props.$displayUpSellProducts) {
      return `
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
          `
    }
  }}
  }
`
const ContentContainer = styled.div<{ $displayUpSellProducts: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;  
  flex: 1;

  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  ${ResponsivePXValue('padding', { mobile: '12px 12px 16px', tablet: '12px 12px 16px', desktop: '16px 16px 14px' })}

  ${(props): CSS => {
    if (props.$displayUpSellProducts) {
      return `
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          `
    }
  }}

  .title {
    ${Ellipsis()};
  }
  .sub-title {
    ${Ellipsis(2)};
  }

  `
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  .dropdown {
    ${ZeroSpace}
    .input-label {
      font-weight: 400;
    }
  }
  
`
const TimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const UpSellProductSheet = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px 8px 6px 6px;
  z-index: 8;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  ${ResponsivePXValue('padding', { mobile: '12px', tablet: '12px', desktop: '16px' })}

  .close-sheet {
    position: absolute;
    z-index: 1;
    ${ResponsivePXValue('right', { mobile: '12px', tablet: '12px', desktop: '0' })}
  }

  .up-sell-product-card{
    border-bottom: 1px solid ${(props): string => props.theme.colors.whites.alabaster};
  }

`

const UpSellProductContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .wine-description {
    ${Ellipsis(5)}
  }
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 6px;
  background: ${(props): string => props.theme.colors.misc.overlay};
`

const LinkContainer = styled.a`
  cursor: pointer;
  text-decoration: none;
`
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('width', '14px')}
  ${ResponsivePXValue('height', '14px')}
`
const UpSellProductContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const getCategoryBackground = (background: CategoryBackgroundEnum): string => {
  switch (background) {
    case CategoryBackgroundEnum.DARK_GREY:
      return theme.colors.slates.bitter
    case CategoryBackgroundEnum.LIGHT_GREY:
      return theme.colors.slates.celeste
    case CategoryBackgroundEnum.YELLOW:
      return theme.colors.yellows.sunglow
    case CategoryBackgroundEnum.ORANGE:
      return theme.colors.oranges.burntSienna
    case CategoryBackgroundEnum.GREEN:
      return theme.colors.greens.asparagus
    case CategoryBackgroundEnum.BLUE:
      return theme.colors.blues.chetwodeBlue
    case CategoryBackgroundEnum.RED:
      return theme.colors.reds.desire
    case CategoryBackgroundEnum.RASPBERRY:
      return theme.colors.reds.raspberry
  }
}

export enum DisplayTypeEnum {
  STANDARD = 'STANDARD',
  PARING = 'PARING',
}

export interface MealKitCardProps {
  mealKit: MealKitDishListFragment
  className?: string
  loading?: boolean
}

interface MealKitCardState {
  displayType: DisplayTypeEnum
  chosenPairing: number
  wineQuantities: number[]
  addToCartInterrupted: boolean
  activeModal: MealkitModalEnum
  displayReactivationModal: boolean
  loading: boolean
  portionSize: NumberOfPortionsEnum
  slug: string
  displayLunchModal: boolean
}

const DEFAULT_STATE: MealKitCardState = {
  displayType: DisplayTypeEnum.STANDARD,
  chosenPairing: 0,
  wineQuantities: [],
  addToCartInterrupted: false,
  activeModal: MealkitModalEnum.NONE,
  displayReactivationModal: false,
  loading: false,
  portionSize: NumberOfPortionsEnum.SERVES_2,
  slug: '',
  displayLunchModal: false,
}

const _isUpSellProductAvailable = (upSellProduct: UpSellFrozenMealDishFragment | UpSellMarketProductFragment | UpSellWineFragment): boolean => {
  let available = true

  if (upSellProduct.__typename === 'FrozenMealDish') {
    for (let i = 0; i < upSellProduct?.products?.length; i++) {
      if (upSellProduct.products[i].availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY ||
           upSellProduct.products[i].availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE ||
           upSellProduct.products[i].availability === ProductAvailabilityEnum.NOT_AVAILABLE ||
           upSellProduct.products[i].stockStatus === ProductStockStatusEnum.OUT_OF_STOCK) {
        available = false
        break
      }
    }
  } else if (upSellProduct.__typename === 'MarketProduct') {
    if (upSellProduct?.availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY ||
          // upSellProduct.availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE ||
          upSellProduct.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK) {
      available = false
    }
  } else if (upSellProduct.__typename === 'Wine') {
    if (upSellProduct.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK || upSellProduct.stockCount === 0) {
      available = false
    }
  }

  return available
}

export const getUpSellProducts = (mealKit: MealKitDishListFragment): (UpSellFrozenMealDishFragment | UpSellMarketProductFragment | UpSellWineFragment)[] => {

  const upSellProducts: (UpSellFrozenMealDishFragment | UpSellMarketProductFragment | UpSellWineFragment)[] = []

  if (mealKit?.upSellMarketProducts) {
    const availableMarketProducts = mealKit?.upSellMarketProducts.filter((product) => _isUpSellProductAvailable(product))
    upSellProducts.push(...availableMarketProducts)
  }

  if (mealKit?.upSellFrozenMealDishes) {
    const availableFrozenMealDishes = mealKit?.upSellFrozenMealDishes.filter((product) => _isUpSellProductAvailable(product))
    upSellProducts.push(...availableFrozenMealDishes)
  }

  // if (mealKit?.upSellWines) {
  //   const availableWines = mealKit?.upSellWines.filter((product) => _isUpSellProductAvailable(product))
  //   upSellProducts.push(...availableWines)
  // }

  if (mealKit?.activeParings?.length > 0 && _isUpSellProductAvailable(mealKit?.activeParings[0]?.wine as unknown as UpSellWineFragment)) {
    // upSellProducts.push(...mealKit?.activeParings?.map((pairing) => pairing.wine as unknown as UpSellWines).filter((wine) => _isUpSellProductAvailable(wine)))
    upSellProducts.push(mealKit?.activeParings[0]?.wine as unknown as UpSellWineFragment)
  }

  upSellProducts.sort((a, b) => {
    if (a.__typename > b.__typename) {
      return -1
    } else if (a.__typename < b.__typename) {
      return 1
    } else {
      return 0
    }
  })

  return upSellProducts
}

export const convertEnumToNum = (defaultPortion: NumberOfPortionsEnum): number => {
  switch (defaultPortion) {
    case NumberOfPortionsEnum.SERVES_1:
      return 1
    case NumberOfPortionsEnum.SERVES_2:
      return 2
    case NumberOfPortionsEnum.SERVES_3:
      return 3
    case NumberOfPortionsEnum.SERVES_4:
      return 4
    default:
      break
  }
}

export const MealKitCard = React.memo(({ mealKit, className }: MealKitCardProps): JSX.Element => {

  const [state, setState] = useState<MealKitCardState>(DEFAULT_STATE)
  const config = useConfig()
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const [form] = useForm()
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [addDishToBox] = useAddDishToUserMenuMutation()
  const [removeItemFromCart] = useRemoveDishFromUserMenuMutation()
  const { logAddToCart, logRemoveFromCart } = useAnalytics()
  const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const subscription: UserSubscriptionFragment = registeredUser?.subscriptions?.find((subscription) => subscription.id === userCartData?.currentUser?.activeMenu?.subscription?.id)
  const hasSelectedPlan = userCartData?.currentUser?.checkoutStatus?.hasSelectedPlan
  const category = mealKit?.mealKitCategories[0]
  const hasLunchCategory = !category?.canSubscribe
  const product = mealKit?.products?.find(product => { return product.portionSize === (hasLunchCategory ? NumberOfPortionsEnum.SERVES_1 : subscription?.numberOfPortions) as unknown as PortionSizeEnum })
  const dishTime = product ? `${product?.overallTime} mins` : `${mealKit?.overallTime?.min} - ${mealKit?.overallTime?.max} mins`
  const background = getCategoryBackground(category.background)
  const buttonText = hasSelectedPlan ? (mealKit.quantityInCart > 0 ? 'ADD ANOTHER' : (isMobile ? 'ADD' : 'ADD TO ORDER')) : 'GET STARTED'
  const buttonColor = 'black'
  const isDisplayingPairing = state.displayType === DisplayTypeEnum.PARING
  const checkoutStatus = userCartData?.currentUser?.checkoutStatus
  const slug = mealKit?.products?.find(product => { return product?.portionSize === defaultPortions as unknown as PortionSizeEnum })?.slug
  const selectOptions: SelectOption[] = [
    {
      title: 'Serves 1',
      value: NumberOfPortionsEnum.SERVES_1,
    },
    {
      title: 'Serves 2',
      value: NumberOfPortionsEnum.SERVES_2,
      disabled: hasLunchCategory,
    },
    {
      title: 'Serves 3',
      value: NumberOfPortionsEnum.SERVES_3,
      disabled: hasLunchCategory,
    },
    {
      title: 'Serves 4',
      value: NumberOfPortionsEnum.SERVES_4,
      disabled: hasLunchCategory,
    },

  ]

  const sessionSlugs = sessionStorage.getItem('SlugSessionString')
  const slugSessionObject :{ [k: string]: NumberOfPortionsEnum } = JSON.parse(sessionSlugs) || {}
  const sluggedPortion = slugSessionObject[mealKit?.slug]

  let defaultPortions:NumberOfPortionsEnum
  if (hasLunchCategory) {
    defaultPortions = NumberOfPortionsEnum.SERVES_1
  } else if (sluggedPortion) {
    defaultPortions = sluggedPortion
  } else if (appData.app.defaultPortions) {
    defaultPortions = appData.app.defaultPortions
  } else {
    defaultPortions = NumberOfPortionsEnum.SERVES_2
  }

  useEffect(() => {
    const list: number[] = []
    for (let i = 0; i < mealKit?.activeParings?.length; i++) {
      list.push(1)
    }
    if (mealKit?.activeParings) {
      setState((prevState) => update(prevState, {
        wineQuantities: { $set: list },
        slug: { $set: slug },
      }))
    }
  }, [mealKit?.activeParings])

  const isAvailable = (): boolean => {
    let available = true
    for (let i = 0; i < mealKit.products.length; i++) {
      if (mealKit.products[i].availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY) {
        available = false
        break
      }
    }
    return available
  }

  const _handleOnAdd = (): void => {

    if (hasLunchCategory) {
      setState((prevState) => update(prevState, {
        displayLunchModal: { $set: true },
      }))
    }

    if (!hasSelectedPlan) {
      if (config.isBrowser()) {
        window.sessionStorage.setItem('mealKitCategoryId', mealKit.mealKitCategories[0].id)
      }
      navigate('/meal-kit/plans')
    } else if (checkoutStatus?.hasPausedSubscription) {
      // show reactivation modal
      _switchReactivateSubscriptionModal(true)
    } else {
      const noDefaultAddress = !userDetailsData?.currentUser?.hasDefaultAddress

      setState((prevState) => update(prevState, {
        addToCartInterrupted: { $set: noDefaultAddress || !isAvailable() },
      }))

      if (noDefaultAddress) {
        ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
      } else if (!isAvailable()) {
        ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
      } else {
        _handleShowAddMealKit()
      }
    }
  }

  useEffect(() => {
    if (state.addToCartInterrupted) {
      _handleOnAdd()
    }
  }, [userDetailsData?.currentUser?.addresses, isAvailable()])

  useEffect(() => {
    setState((prevState) => update(prevState, {
      portionSize: { $set: defaultPortions },
      slug: { $set: slug },
    }))
  }, [userDetailsData])

  const _handleShowAddMealKit = (): void => {
    if (userCartData?.currentUser?.checkoutStatus?.hasSelectedPortionSize) {
      _handleAddDishToBox()
    } else {
      setState((prevState) => update(prevState, {
        activeModal: { $set: MealkitModalEnum.SERVING_SIZE },
      }))
    }
  }

  const _handleServingSizeSelected = () => {
    setState((prevState) => update(prevState, {
      activeModal: { $set: MealkitModalEnum.NONE },
    }))
    _handleAddDishToBox()
  }

  const _handleAddDishToBox = async (): Promise<void> => {
    _setLoading(true)

    let portionSize = state.portionSize

    if (hasLunchCategory) {
      portionSize = NumberOfPortionsEnum.SERVES_1
    }

    try {
      await addDishToBox({
        variables: {
          input: {
            dishId: mealKit?.id,
            portionSize,
          },

        },
        refetchQueries: [{ query: UserCartDocument }],
        awaitRefetchQueries: true,
      })
      // Analytics
      logAddToCart({
        item_name: mealKit?.name,
        item_id: mealKit?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.id,
        price: mealKit?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.price,
        item_brand: 'UCOOK',
        item_category: mealKit?.mealKitCategories?.map((cat) => cat.id)?.join(', '),
        item_variant: mealKit?.mealKitCategories?.map((cat) => cat.title)?.join(', '),
        item_list_name: 'Meal Kit',
        quantity: 1,
      })

      addToast('Item successfully added to your order. Hit \'save\' in your cart before you go, or this dish won’t be saved.', {
        appearance: 'warning',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _setLoading(false)
  }

  const _handleOnCenterClick = async (): Promise<void> => {
    if (mealKit.quantityInCart === 0) {
      _handleOnAdd()
    }
  }

  const getDishId = (id: string, cartDishesArray: UserMenuFragment): string => {
    for (let i = 0; i < cartDishesArray?.dishes?.length; i++) {
      if (cartDishesArray?.dishes[i].dish?.id === id) {
        return cartDishesArray?.dishes[i].id
      }
    }
  }
  const _handleOnRemove = async (): Promise<void> => {
    _setLoading(true)

    const cartData = userCartData?.currentUser?.activeMenu
    // TODO: add to state
    const cartDishId = getDishId(mealKit.id, cartData)

    try {

      if (mealKit.quantityInCart > 0) {
        await removeItemFromCart({
          variables: {
            userMenuDishId: cartDishId,
          },
          refetchQueries: [{
            query: UserCartDocument,
          }],
          awaitRefetchQueries: true,
        })
      }
      let portionSize = state.portionSize

      if (hasLunchCategory) {
        portionSize = NumberOfPortionsEnum.SERVES_1
      }
      // Analytics
      logRemoveFromCart({
        item_name: mealKit?.name,
        item_id: mealKit?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.id,
        price: mealKit?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.price,
        item_brand: 'UCOOK',
        item_category: mealKit?.mealKitCategories?.map((cat) => cat.id)?.join(', '),
        item_variant: 'MealKit',
        item_list_name: 'MealKit',
        quantity: 1,
      })

      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _setLoading(false)

  }

  const _setLoading = (loading: boolean) => {
    setState((prevState) => update(prevState, {
      loading: { $set: loading },
    }))
  }

  const _showPairing = () => {
    setState((prevState) => update(prevState, {
      displayType: { $set: DisplayTypeEnum.PARING },
    }))
  }

  const _showDefault = () => {
    setState((prevState) => update(prevState, {
      displayType: { $set: DisplayTypeEnum.STANDARD },
    }))
  }

  const _switchReactivateSubscriptionModal = (display: boolean): void => {
    setState((prevState) => update(prevState, {
      displayReactivationModal: { $set: display },
    }))
  }

  const _onReactivateSubscription = () => {
    _handleAddDishToBox()
    CartPlugin.shared().open()
    _switchReactivateSubscriptionModal(false)
  }

  const _handlePortionSizeChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'numberOfPortions') {
          const slug = mealKit?.products?.find(product => { return product.portionSize === field.value as unknown as PortionSizeEnum }).slug
          setState((prevState) => update(prevState, {
            portionSize: { $set: field.value },
            slug: { $set: slug },
          }))

          slugSessionObject[mealKit?.slug] = field.value
          const slugSessionObjectString = JSON.stringify(slugSessionObject)
          sessionStorage.setItem('SlugSessionString', slugSessionObjectString)
        }
      })
    })
  }

  const pdpLink = `/meal-kit/${mealKit.slug}`
  const _handleLinkClicked = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate(pdpLink)
  }

  const upSellProducts = getUpSellProducts(mealKit)
  const bestBefore = `EAT WITHIN ${mealKit.cookWithin} DAYS`
  const categoryTitle = hasLunchCategory ? '*New Lunch' : category.title
  const displayUpSellProducts = isDisplayingPairing && upSellProducts.length > 0

  let upSellProduct: UpSellFrozenMealDishFragment | UpSellMarketProductFragment | UpSellWineFragment
  let index: number
  const numberDefaultPortions = convertEnumToNum(defaultPortions)

  const lunchModalTitle = `Lunch - ${mealKit.name}`

  return (
    <Container className={className} $displayUpSellProducts={displayUpSellProducts}>
      <LunchModal
        open={state.displayLunchModal}
        title={lunchModalTitle}
        image={mealKit.coverImage} />
      <MealkitModal
        activeModal={state.activeModal}
        onSubmit={_handleServingSizeSelected}
        onClose={() => { setState({ ...state, activeModal: MealkitModalEnum.NONE }) }} />
      <ReactivateSubscriptionModal
        open={state.displayReactivationModal}
        onReactivation={_onReactivateSubscription}
        onClose={() => { _switchReactivateSubscriptionModal(false) }} />
      <If condition={mealKit.discoveryHealthyDiningItem}>
        <DiscoveryVitality />
      </If>
      <If condition={mealKit?.upSellText?.length > 0}>
        <FloatingUpSell text={mealKit.upSellText} />
      </If>
      <LinkContainer href={pdpLink} onClick={_handleLinkClicked}>
        <ImageContainer $displayUpSellProducts={displayUpSellProducts}>
          <ResponsiveImage image={mealKit.coverImage} />
        </ImageContainer>
      </LinkContainer>
      <ContentContainer $displayUpSellProducts={displayUpSellProducts}>

        <FormContainer>
          <Pill className='flex-pill' title={categoryTitle} backgroundColor={background} />
          <Form className='no-margin' form={form} onFieldsChange={_handlePortionSizeChange}>
            <SelectInput
              name='numberOfPortions'
              value={defaultPortions}
              readOnly={true}
              placeholder={`Serves ${numberDefaultPortions}`}
              itemsToDisplay={4}
              options={selectOptions}
              rules={[{ required: true }]}
              showLabel={false}
              className='dropdown' />
          </Form>
        </FormContainer>
        <Spacer universal='12px' />
        <LinkContainer href={pdpLink} onClick={_handleLinkClicked}>
          <TextContainer>
            <Heading className='title' variant='h5'> {mealKit.name} </Heading>
            <Spacer mobile='4px' desktop='8px' />
            <Paragraph className='sub-title' variant='p1'> {mealKit.subTitle}</Paragraph>
            <Spacer mobile='4px' desktop='8px' />
            <TimeContainer>
              <Paragraph bold variant='p2' color={theme.colors.whites.silver}>{bestBefore}</Paragraph>
              <Spacer universal='4px' variant='horizontal' />
              <TimeContainer>
                <IconContainer>
                  <Icon icon={IconEnum.ALARM_OUTLINE} color={theme.colors.whites.silver} />
                </IconContainer>
                <Spacer universal='4px' variant='horizontal' />
                <Paragraph bold variant='p2' color={theme.colors.whites.silver}> {dishTime} </Paragraph>
                <Spacer universal='4px' variant='horizontal' />
              </TimeContainer>
            </TimeContainer>
            <Spacer mobile='8px' desktop='12px' />
          </TextContainer>
        </LinkContainer>
        <ButtonContainer>
          <Button
            className='flex-1'
            loading={state.loading}
            color={buttonColor}
            title={buttonText}
            amount={mealKit.quantityInCart}
            onClick={_handleOnCenterClick}
            onLeftIconClick={_handleOnRemove}
            onRightIconClick={_handleOnAdd}/>
          <If condition={upSellProducts.length > 0}>
            <Spacer variant='horizontal' universal='12px' />
            <Button
              color='tundora'
              title='ADD EXTRAS'
              icon={isMobile ? IconEnum.ADD_CIRCLE_OUTLINE: null}
              onClick={_showPairing} />
          </If>
        </ButtonContainer>
      </ContentContainer>
      <If condition={displayUpSellProducts}>
        <Overlay>
          <Transition state={displayUpSellProducts} timeout={TRANSITION_DURATION}>
            {(stage: Stage, shouldMount: boolean) => shouldMount && (
              <UpSellProductSheet style={{
                transition: `${TRANSITION_DURATION}ms`,
                transform: stage === 'enter' ? 'translateY(0)' : 'translateY(100%)',
              }}>
                <UpSellProductContentContainer>
                  <Button
                    className='close-sheet'
                    color='transparent'
                    icon={IconEnum.CLOSE_OUTLINE}
                    iconColor={theme.colors.greys.liteCodGrey}
                    onClick={_showDefault} />
                  <UpSellProductContainer>
                    <For each='upSellProduct' of={upSellProducts.slice(0, 3)} index='index'>
                      <UpSellProductCard className='up-sell-product-card' meal={upSellProduct} key={upSellProduct.id} />
                      <If condition={index < upSellProducts.length - 1}>
                        <Spacer universal='8px' />
                      </If>
                    </For>
                  </UpSellProductContainer>
                </UpSellProductContentContainer>
              </UpSellProductSheet>
            )}
          </Transition>
        </Overlay>
      </If>
    </Container>
  )
})

MealKitCard.displayName = 'MealKitCard'
